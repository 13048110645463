<template>
    <div>
        <adContent></adContent>
        <p class="home-text">
            Home - play1000+free games online on pc
        </p>
        <div class="game-cont">
            <div class="game-card"
                 v-for="item in gameList"
                 :key="item.id"
                 @click="goDetails(item.id)"
            >
                <img :src="item.logo" alt="">
            </div>
            <span v-for="i in 8" :key="i"></span>
        </div>
        <div class="game-blurb noselect">
            Welcome to {{blurbUrl}} Here, various free online games can be played directly in your browser without any downloads. Our collection of online games will provide you with hours ofentertainment. Invite your friends over and enjoy these popular games together. From classic arcade games to car games, cricket games, puzzles, mahjong, and more, there's something for everyone in our game categories. We have carefully selected the best online games, and we review and add new games every day, so you can learn new skills as you play! Let's start playing
        </div>
    </div>
</template>

<script>
import {
    getGame,
} from "@/api/games";
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent
        },
        data() {
            return {
                gameList: [],
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            async getList(){
                this.gameList = await getGame();
            },
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.home-text{
    margin: 12px 0 17px;
    font-family: Rubik, Rubik;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 19px;
    text-align: center;
    font-style: normal;
    text-transform: none;
}
.game-cont{
    display: flex;
    padding: 0 16px;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: 768px) {
        .game-card{
            margin-right: 7px;
            margin-bottom: 7px;
        }
    }
    .game-card{
        width: 105px;
        height: 105px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 14px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    span{
        width: 105px;
    }
}
.game-blurb{
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: Poppins, Poppins;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
</style>