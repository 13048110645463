<template>
    <div>
        <div class="header">
            <img class="class pointer" src="@/assets/slices/class.png" alt="">
            <img class="logo pointer" src="@/assets/slices/logo.png" alt="" @click="goIndex">
            <img class="search pointer" src="@/assets/slices/search.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showMenu: false,
                searchText: ''
            }
        },
        mounted() {

        },
        methods: {
            goIndex(){
                if(this.$route.path == '/'){
                    this.$router.go(0)
                }else{
                    this.$router.push('/')
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .header{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .header{
    width: 100%;
  }
}
.pointer{
    cursor: pointer;
}
.header{
    height: 60px;
    background: #18052D;
    position: fixed;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .logo{
        width: 195px;
        height: 47px;
    }
    .class{
        width: 32px;
        height: 32px;
    }
    .search{
        width: 26px;
        height: 26px;
    }
}
</style>